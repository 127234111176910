import firebase from 'firebase/compat/app';
import {
  query,
  where,
  or,
  getCountFromServer,
  collection,
  Timestamp,
  updateDoc,
  doc,
  getDoc
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  deleteObject,
  getDownloadURL,
  uploadString
} from 'firebase/storage';
import { db } from '../Firebase';
import {
  deleteMainDocumentFirebase,
  deleteAddendumFirebase,
  deleteExtraDocFirebase
} from './storage';
const dbRef = db.collection('DocumentsData');
const storage = getStorage();
export const getTotalSignatures = async () => {
  const dbCollection = collection(db, 'DocumentsData');
  const q = query(
    dbCollection,
    or(where('status', '==', 'published'), where('status', '==', 'finished'))
  );
  const snapshot = await getCountFromServer(q);

  return snapshot.data().count;
};

const documentInformationObject = (document) => {
  return {
    addendums: document.addendums,
    contractingParty: document.contractingParty,
    countSignatures: document.countSignatures,
    createdDate: document.createdDate,
    docId: document.docId,
    editors: document.editors,
    editorsEmails: document.editorsEmails,
    endDate: document.endDate,
    extraDocs: document.extraDocs,
    guestSignatureEnabled: document.guestSignatureEnabled,
    hasSignatureOrder: document.hasSignatureOrder,
    initiatorUserId: document.initiatorUserId,
    name: document.name,
    owner: {
      id: document.owner.id,
      name: document.owner.name,
      lastName: document.owner.lastName,
      type: document.owner.type
    },
    reminderEmailDateSent: document.reminderEmailDateSent,
    signers: document.signers,
    signersEmails: document.signersEmails,
    status: document.status,
    totalSignatures: document.totalSignatures,
    type: document.type,
    version: document.version
  };
};

const extraInformationObject = (doc) => {
  return {
    amount: doc.amount,
    countAccepted: doc.countAccepted,
    currency: doc.currency,
    description: doc.description,
    file: {
      name: doc.file.name,
      time: doc.file.time,
      url: doc.file.url
    },
    signatureDate: doc.signatureDate,
    signatureType: {
      digital: doc.signatureType.digital,
      fiel: doc.signatureType.fiel,
      simple: doc.signatureType.simple
    },
    totalToAccept: doc.totalToAccept
  };
};

const voToEntityExtraInformationObject = (doc) => {
  return {
    amount: doc.amount,
    countAccepted: doc.countAccepted,
    currency: doc.currency,
    description: doc.description,
    file: {
      name: doc.file.name,
      time: doc.file.time,
      url: doc.file.url
    },
    signatureDate: doc.signatureDate,
    signatureType: {
      digital: doc.signatureType.digital,
      fiel: doc.signatureType.fiel,
      simple: doc.signatureType.simple
    },
    totalToAccept: doc.totalToAccept
  };
};

const fileObject = (doc) => {
  return {
    signed: {
      name: doc.signed.name,
      time: doc.signed.time,
      userId: doc.signed.userId,
      url: doc.signed.url
    },
    xml: {
      name: doc.xml.name,
      time: doc.xml.time,
      userId: doc.xml.userId,
      url: doc.xml.url
    }
  };
};

const seguriDataObject = (doc) => {
  return {
    processId: doc.processId
  };
};

const returnApproverObject = (approver) => {
  return {
    approved: approver.approved,
    email: approver.email,
    name: approver.name,
    role: 'approver'
  };
};

const returnSignerObject = (signer) => {
  return {
    signed: signer.signed,
    email: signer.email,
    name: signer.name,
    notification: signer.notification,
    order: signer.order
  };
};

// Searches for editors or signers
// arrayEmailsType receives string "editors" or "signers"
export const getDocsByArrayEmailsType = async (arrayEmailsType, userMail) => {
  const snapshotArray = await dbRef
    .where(arrayEmailsType, 'array-contains', userMail)
    .get()
    .catch((error) => {
      throw error;
    });

  const docs = snapshotArray.docs.map((doc) => {
    return { ...documentInformationObject(doc.data()), id: doc.id };
  });

  const listDraftDocs = docs.filter((doc) => doc.status === 'draft');
  const listPublishedDocs = docs.filter((doc) => doc.status !== 'draft');

  if (arrayEmailsType === 'signersEmails') {
    const listFilteredPublishedDocs = [];
    listPublishedDocs.forEach((doc) => {
      if (!doc.hasSignatureOrder) {
        listFilteredPublishedDocs.push(doc);
        return;
      }
      doc.signers.forEach((signer) => {
        if (
          signer.email === userMail &&
          signer.order <= doc.countSignatures + 1
        ) {
          listFilteredPublishedDocs.push(doc);
        }
      });
    });
    return {
      draftDocs: listDraftDocs,
      publishedDocs: listFilteredPublishedDocs
    };
  } else {
    return { draftDocs: listDraftDocs, publishedDocs: listPublishedDocs };
  }
};

export const getDocExtraInformationRef = async (docId) => {
  const mainDocRef = dbRef.doc(docId);
  const docRef = doc(
    mainDocRef,
    'DocumentExtraInformation/DocumentExtraInformation'
  );
  return docRef;
};

export const getDocExtraInformation = async (docId) => {
  return dbRef
    .doc(docId)
    .collection('DocumentExtraInformation')
    .doc('DocumentExtraInformation')
    .get()
    .then((doc) => {
      const extraInfo = doc.data();
      return extraInformationObject(extraInfo);
    })
    .catch((error) => {
      throw error;
    });
};

export const getDocSeguriData = async (docId) => {
  return dbRef
    .doc(docId)
    .collection('SeguriData')
    .doc('SeguriData')
    .get()
    .then((doc) => {
      const seguriDataInfo = doc.data();
      return seguriDataObject(seguriDataInfo);
    })
    .catch((error) => {
      throw error;
    });
};

export const getDocFile = async (docId) => {
  return dbRef
    .doc(docId)
    .collection('File')
    .doc('File')
    .get()
    .then((doc) => {
      const fileInfo = doc.data();
      return fileObject(fileInfo);
    })
    .catch((error) => {
      throw error;
    });
};

export const getDocsUserByInitiatorUserId = async (userId) => {
  const snapshotInitiator = await dbRef
    .where('initiatorUserId', '==', userId)
    .get()
    .catch((error) => {
      throw error;
    });

  const docs = snapshotInitiator.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
  const listDraftDocs = docs.filter((doc) => doc.status === 'draft');
  const listPublishedDocs = docs.filter((doc) => doc.status !== 'draft');
  return { draftDocs: listDraftDocs, publishedDocs: listPublishedDocs };
};

export const saveDocumentDataFirebase = async (document) => {
  document.signatureDate = document.signatureDate.replace(/-/g, '/');
  const signatureDateTime = new Date(document.signatureDate);
  signatureDateTime.setHours(23, 59, 59, 999);

  document.reminderEmailDateSent = firebase.firestore.Timestamp.now();
  document.signatureDate =
    firebase.firestore.Timestamp.fromDate(signatureDateTime);
  const documentToSave = documentInformationObject(document);
  const extraInfoToSave = voToEntityExtraInformationObject(document);

  const savedDocument = await dbRef
    .add(documentToSave)
    .then(async (docRef) => {
      const documentExtraInfoPath = `DocumentsData/${docRef.id}/DocumentExtraInformation`;
      const savedDoc = docRef;
      await db
        .collection(documentExtraInfoPath)
        .doc('DocumentExtraInformation')
        .set(extraInfoToSave);
      return savedDoc;
    })
    .catch((error) => {
      throw error;
    });

  return savedDocument;
};

export const updateDocumentDataFirebase = async (docId, document) => {
  const batch = db.batch();
  const documentToSave = documentInformationObject(document);
  const extraInfoToSave = voToEntityExtraInformationObject(document);

  const docRef = dbRef.doc(docId);
  batch.update(docRef, documentToSave);

  const extraRef = dbRef
    .doc(docId)
    .collection('DocumentExtraInformation')
    .doc('DocumentExtraInformation');
  batch.update(extraRef, extraInfoToSave);

  batch.commit().catch((error) => {
    throw error;
  });
};

export const deleteDocumentOtherFiles = async (
  docId,
  fileType,
  fileToDelete
) => {
  dbRef.doc(docId).update({
    [fileType]: firebase.firestore.FieldValue.arrayRemove(fileToDelete)
  });
};

export const deleteFileFromStorageByReference = (ref) => {
  deleteObject(ref)
    .then(() => {
      // File deleted successfully
    })
    .catch((error) => {
      console.log('ERROR: ', error);
    });
};

export const updateDocumentPDFFile = async (docId, dataPDF) => {
  const extraInfo = await getDocExtraInformation(docId);
  const extraInfoRef = await getDocExtraInformationRef(docId);
  const currentFileRef = ref(storage, extraInfo.file.url);
  deleteFileFromStorageByReference(currentFileRef);
  const newFileRef = ref(storage, `documents/${docId}/docs/signedDocument.pdf`);
  const documentURL = await uploadString(newFileRef, dataPDF, 'data_url').then(
    async () => {
      const url = await getDownloadURL(
        ref(storage, `documents/${docId}/docs/signedDocument.pdf`)
      );
      return url;
    }
  );
  await updateDoc(extraInfoRef, { 'file.url': documentURL });
};

export const updateDocumentFile = async (docId, finalFile, xmlFile) => {
  const batch = db.batch();
  const filesDocument = { signed: { ...finalFile }, xml: { ...xmlFile } };
  const filesToSave = fileObject(filesDocument);

  const extraRef = dbRef.doc(docId).collection('File').doc('File');
  batch.set(extraRef, filesToSave);

  batch.commit().catch((error) => {
    throw error;
  });
};

export const addDocumentOtherFiles = async (docId, fileType, fileToAdd) => {
  dbRef.doc(docId).update({
    [fileType]: firebase.firestore.FieldValue.arrayUnion(fileToAdd)
  });
};

export const deleteDocumentFirebase = async (document) => {
  // delete document database
  dbRef
    .doc(document.id)
    .delete()
    .then(() => {
      // delete main file storage
      return deleteMainDocumentFirebase(
        document.initiatorUserId,
        document.file
      );
    })
    .then(() => {
      // delete addendums storage
      return Promise.all(
        document.addendums.map(async (addendum) =>
          deleteAddendumFirebase(document.initiatorUserId, addendum)
        )
      );
    })
    .then(() => {
      // delete extra files storage
      return Promise.all(
        document.extraDocs.map(async (extraDoc) =>
          deleteExtraDocFirebase(document.initiatorUserId, extraDoc)
        )
      );
    })
    .catch((error) => {
      throw error;
    });
};

// DANGER: THIS FUNCTION SHOULD ONLY BE USED TO UPDATE THE DATABASE WHEN MERGING IN MASTER
// export const timestampMigration = async () => {
//   const documentSnapshots = await db.collection('DocumentsData').get();

//   documentSnapshots.docs.forEach(async (docSnapshot) => {
//     const documentExtraInfoPath = `DocumentsData/${docSnapshot.id}/DocumentExtraInformation`;

//     const extraInformationRef = db
//       .collection(documentExtraInfoPath)
//       .doc('DocumentExtraInformation');
//     const extraInfoSnapshot = await extraInformationRef.get(
//       extraInformationRef
//     );
//     const extraInfoData = extraInfoSnapshot.data();

//     if (!extraInfoData) {
//       return;
//     }

//     if (typeof extraInfoData.signatureDate !== 'string') {
//       return;
//     }

//     let [year, month, day] = extraInfoData.signatureDate.split('-');
//     month = (parseInt(month) - 1).toString();
//     const newDate = new Date();
//     newDate.setFullYear(year);
//     newDate.setMonth(month);
//     newDate.setDate(day);
//     newDate.setHours(0, 0, 0, 0);

//     console.log(newDate);

//     const newFirebaseDate = Timestamp.fromDate(newDate);
//     try {
//       await extraInformationRef.update({
//         signatureDate: newFirebaseDate
//       });
//       console.info(
//         `Edited document ${docSnapshot.id} with signature date: `,
//         newFirebaseDate.toDate()
//       );
//     } catch (e) {
//       console.error(`Error in ${docSnapshot.id}`);
//       console.error(e);
//     }
//   });
// };

export const updatePropInDocInFirebaseById = async (idDoc, prop, value) => {
  await dbRef.doc(idDoc).update({
    [prop]: value
  });
};

/**
 * Updates the reminderEmailDateSent property to a now Timestamp
 * @param {number} idDoc
 */
export const updateReminderEmailDateSent = async (idDoc) => {
  const timestampNow = Timestamp.now();

  try {
    const docNow = await getDocumentById(idDoc);

    const lastSentDate = docNow.reminderEmailDateSent.toDate();
    const diffTime = Math.abs(timestampNow.toDate() - lastSentDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays <= 0) {
      throw new Error('Cannot resend an email this soon');
    }

    await dbRef.doc(idDoc).update({
      reminderEmailDateSent: timestampNow
    });
  } catch (error) {
    throw new Error('Could not update timestamp');
  }
  return timestampNow;
};

export const simpleSignDocumentFirebase = async (
  docId,
  currentSigner,
  currentDate
) => {
  const docRef = dbRef.doc(docId);
  const document = (await getDoc(docRef)).data();
  let signers = document.signers;
  signers.forEach((signer) => {
    if (signer.name === currentSigner.name) {
      signer.signed = true;
      signer.dateSignature = currentDate;
    }
  });
  await updateDoc(docRef, {
    signers: signers,
    countSignatures: document.countSignatures + 1
  });
  try {
    return await getDocumentById(docId);
  } catch (error) {
    throw error;
  }
};

export const signDocumentFirebase = async (docId, signer, currentDate) => {
  const oldSigner = returnSignerObject(signer);
  const newSigner = { ...oldSigner, signed: true, dateSignature: currentDate };

  const batch = db.batch();
  const docRef = dbRef.doc(docId);

  batch.update(docRef, {
    signers: firebase.firestore.FieldValue.arrayRemove(oldSigner)
  });
  batch.update(docRef, {
    signers: firebase.firestore.FieldValue.arrayUnion(newSigner)
  });
  batch.update(docRef, {
    countSignatures: firebase.firestore.FieldValue.increment(1)
  });
  try {
    await batch.commit();
    return await getDocumentById(docId);
  } catch (error) {
    throw error;
  }
};

export const approveDocumentFirebase = (doc, userEmail) => {
  const approver = doc.editors.find((editor) => editor.email === userEmail);

  const oldApprover = returnApproverObject(approver);
  const newApprover = { ...oldApprover, approved: true };

  const batch = db.batch();
  const docRef = dbRef.doc(doc.id);
  const extraDocRef = docRef
    .collection('DocumentExtraInformation')
    .doc('/DocumentExtraInformation');

  batch.update(docRef, {
    editors: firebase.firestore.FieldValue.arrayRemove(oldApprover)
  });
  batch.update(docRef, {
    editors: firebase.firestore.FieldValue.arrayUnion(newApprover)
  });
  batch.update(extraDocRef, {
    countAccepted: firebase.firestore.FieldValue.increment(1)
  });
  batch.commit().catch((error) => {
    throw error;
  });
};

export const updateDocumentArrayInFirebase = (
  docId,
  oldUser,
  newUser,
  array
) => {
  const batch = db.batch();

  const docRef = dbRef.doc(docId);

  batch.update(docRef, {
    [array]: firebase.firestore.FieldValue.arrayRemove(oldUser)
  });
  batch.update(docRef, {
    [array]: firebase.firestore.FieldValue.arrayUnion(newUser)
  });
  batch.commit().catch((error) => {
    throw error;
  });
};

// DANGER: THIS FUNCTION SHOULD ONLY BE USED TO UPDATE THE DATABASE WHEN MERGING IN MASTER
// Update all the documents with the field reminderEmailDateSent
// export const updateDocumentsWithReminderDate = async () => {
//   let documentsWithReminder = {};

//   const docs = await dbRef.get();

//   let countDocs = 0;
//   let totalDocsModified = 0;
//   docs.forEach(async (doc) => {
//     countDocs = countDocs + 1;

//     const thisDocRef = dbRef.doc(doc.id);
//     const newTimestamp = firebase.firestore.Timestamp.now();
//     try {
//       await thisDocRef.update({
//         reminderEmailDateSent: newTimestamp
//       });
//       totalDocsModified = totalDocsModified + 1;
//       console.log(`Updated document ${doc.id} with the date: ${newTimestamp}`);
//     } catch (error) {
//       console.log(error);
//     }

//     if (docs.docs.length === totalDocsModified) {
//       console.log(`DONE! Updated ${totalDocsModified} documents!`);
//     }
//   });
// };

// not refactored

export const getDocumentById = async (docId) => {
  const doc = await dbRef.doc(docId).get();
  if (doc.exists) {
    return doc.data();
  } else {
    throw new Error(`document with id ${docId} doesnt exists`);
  }
};

export const publishDocumentFirebase = async (
  docId,
  status,
  processId,
  userData
) => {
  const docRef = dbRef.doc(docId);
  const seguriRef = dbRef.doc(docId).collection('SeguriData').doc('SeguriData');
  const userRef = db.collection('UserData').doc(userData.id);
  const batch = db.batch();

  if (processId !== '') {
    batch.set(seguriRef, { processId: processId });
  }

  batch.update(docRef, { status: status });

  batch.update(userRef, {
    signatures: firebase.firestore.FieldValue.increment(-1),
    signaturesUsed: firebase.firestore.FieldValue.increment(1)
  });
  batch.commit().catch((error) => {
    throw error;
  });
};

export const editDocumentData = async (docId, newData) => {
  try {
    const doc = await dbRef.doc(docId);
    return doc.update(newData);
  } catch (error) {
    console.error('Error updating document: ', error);
  }
};

export const getDocInfoById = async (docId) => {
  let document = await dbRef.doc(docId).get();
  return document;
};

export const returnObjectDocument = (
  data,
  owner,
  id,
  editors,
  // idRelation,
  approved,
  signers
  // roll
) => {
  return {
    ...data,
    id: id,
    editors: [...editors],
    signers: [...signers],
    // idRelation: idRelation,
    approved: approved,
    // roll: roll,
    owner: owner.name
  };
};

export const returnObjectDocumentToSign = (
  data,
  // initiatorName,
  id,
  members, //signers?
  status,
  // idRelation,
  order
) => {
  return {
    ...data,
    id: id,
    members: [...members], //signers
    statusSignature: status,
    // idRelation: idRelation,
    orderToSign: order
    // initiatorName: initiatorName, //owner.name
  };
};
