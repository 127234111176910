import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/index';
import { Provider } from 'react-redux';
import { StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined'
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'always'
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            borderWidth: 1
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1
          },
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderWidth: 1
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true
      }
    }
  },
  palette: {
    primary: {
      main: '#041854'
    },
    secondary: {
      main: '#f77c2e'
    },
    black: {
      main: '#000000'
    }
  },
  typography: {
    fontFamily: 'Roboto',
    allVariants: {
      fontFamily: 'Roboto'
    },
    h1: {
      fontSize: 30,
      fontWeight: 'bold'
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 'bold'
    },
    body1: {
      fontSize: 14
    },
    body2: {
      fontSize: 12
    },
    button: {
      fontSize: 12,
      textTransform: 'none'
    }
  }
});

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
